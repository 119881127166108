import BaseApp from "./baseapp";
import {
    MainApp,
} from "./mainapp";
import JournalManager from "./journalmanager";

export default class AdjustTabHelper {
    app: MainApp;
    journalManager: JournalManager;
    journal_id_select_adjust_view = document.querySelector('.journal_id_select_adjust_view') as HTMLSelectElement;
    save_to_journal_btn = document.querySelector('.save_to_journal_btn') as HTMLButtonElement;
    prompt_input_textarea = document.querySelector(".prompt_input_textarea") as HTMLTextAreaElement;
    tag_input_adjust_view = document.querySelector('.tag_input_adjust_view') as HTMLInputElement;
    adjust_tags_datalist = document.querySelector('#adjust_tags_datalist') as HTMLDataListElement;
    adjust_journal_entry_paging = document.querySelector(".adjust_journal_entry_paging") as HTMLDivElement;
    entries_count_view = document.querySelector(".entries_count_view") as HTMLDivElement;

    constructor(app: MainApp) {
        this.app = app;
        this.journalManager = new JournalManager(this.app);
        this.journalManager.getEntryCard = this.getEntryCard.bind(this);
        this.journalManager.journalSelectList = this.journal_id_select_adjust_view;
        this.journalManager.tagDataList = this.adjust_tags_datalist;
        this.journalManager.entryPagingView = this.adjust_journal_entry_paging;
        this.journalManager.entriesCountView = this.entries_count_view;

        this.journal_id_select_adjust_view.addEventListener('change', async () => {
            this.journalManager.journalId = this.journal_id_select_adjust_view.value;
            this.journalManager.load(true);
            this.app.saveProfileField("selectedAdjustJournalId", this.journalManager.journalId);
        });

        this.save_to_journal_btn.addEventListener('click', async () => {
            const text = this.prompt_input_textarea.value;
            const tags = this.tag_input_adjust_view.value.split(",").map((x: string) => x.trim()).filter((x: string) => x.length > 0);
            const meta = {
                type: "text",
            };
            await this.app.saveJournalEntry(this.journalManager.journalId, text, tags, meta);
        });

        this.prompt_input_textarea.addEventListener('change', () => this.app.saveProfileField("sourceMessageInput", this.prompt_input_textarea.value));
        this.tag_input_adjust_view.addEventListener('change', () => this.app.saveProfileField("selectedAdjustJournalTag", this.tag_input_adjust_view.value));
    }
    async load() {
        this.journalManager.load();
    }
    paint() {
        const currentTag = this.app.profile?.selectedAdjustJournalTag || "";
        this.tag_input_adjust_view.value = currentTag;

        this.app.getFieldFromProfile(this.prompt_input_textarea, "sourceMessageInput");
    }
    getEntryCard(doc: any) {
      const data = doc.data();
      let ownerClass = "";
      if (data.creator === this.app.uid) ownerClass += " journal_feed_owner_user";
      else ownerClass += " journal_feed_shared_user";
  
      let timeStr = BaseApp.showGmailStyleDate(new Date(data.created), true);
      const html = `<div class="journal_entry_list_item block rounded m-1 p-2 border border-gray-300 ${ownerClass}"
          data-id="${doc.id}">
          <div class="journal_entry_list_item_text">${data.entryText}</div>
          <div class="journal_entry_list_item_meta">${timeStr}</div>
        </div>`;
      const ctl = document.createElement("div");
      ctl.innerHTML = html;
      const card: any = ctl.children[0];
      return card;
    }
}
